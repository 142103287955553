import logo from '~/assets/images/curtains24-black-yellow.svg';
import { cn, sortByField } from '~/lib/utils';
import { Fragment, useState } from 'react';
import NavbarItem from '~/modules/layout/components/nav/navbar-item';
import { Link } from '@remix-run/react';
import Icon from '~/components/ui/icon';
import GlobalLoading from '~/components/misc/global-loading';
import { useNav } from '~/modules/layout/components/nav/nav-provider';
import EditMessage from '~/modules/shared/components/edit-message';
import LazySearchWidget from '~/modules/search/components/widget/lazy-search-widget';

type NavbarProps = {
  editPolygonId?: string;
  cartCount: JSX.Element;
  algoliaApiKey: string;
  algoliaAppId: string;
};

export default function Navbar({
  editPolygonId,
  cartCount,
  algoliaApiKey,
  algoliaAppId,
}: NavbarProps) {
  const { topBar, main, mobile } = useNav();
  let [showMobileNav, setShowMobileNav] = useState(false);

  return (
    <>
      {topBar && <TopBar {...topBar} />}
      <div
        style={{ viewTransitionName: 'nav' }}
        className="sticky left-0 top-0 z-20 bg-card"
      >
        {/*Mobile nav*/}
        <div
          className={cn('relative z-20 lg:hidden', {
            hidden: !showMobileNav,
          })}
          role="dialog"
          aria-modal="true"
        >
          <div
            onClick={() => setShowMobileNav(false)}
            className="fixed inset-0 bg-black bg-opacity-25"
          ></div>

          <div className="fixed inset-0 z-20 flex max-w-xs">
            <div className="relative flex w-full flex-col overflow-y-auto bg-white pb-12 shadow-xl">
              <div className="flex px-4 pb-2 pt-5">
                <button
                  onClick={() => setShowMobileNav(false)}
                  type="button"
                  className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                >
                  <Icon name="X" />
                </button>
              </div>

              <div className="mt-2">
                {(mobile?.attributes?.items?.data ?? []).map((i) => [
                  <div key={i.id} className="space-y-6 border-b px-4 py-6">
                    {...i.attributes.children.data.map((c) => (
                      <div
                        key={c.id}
                        className="grid grid-cols-1 items-start gap-x-6 gap-y-10"
                      >
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10">
                          <div>
                            <Link
                              to={c.attributes.url}
                              className="font-medium text-gray-900"
                              onClick={() => setShowMobileNav(false)}
                              unstable_viewTransition
                            >
                              {c.attributes.title}
                            </Link>
                            {!!c.attributes.children.data.length && (
                              <ul
                                role="list"
                                aria-labelledby="mobile-featured-heading-0"
                                className="mt-6 space-y-6"
                              >
                                {c.attributes.children.data.map((child) => (
                                  <li
                                    key={child.attributes.url}
                                    className="flex"
                                  >
                                    <Link
                                      to={child.attributes.url}
                                      className="pl-6 text-gray-500"
                                      onClick={() => setShowMobileNav(false)}
                                      prefetch="viewport"
                                      unstable_viewTransition
                                    >
                                      {child.attributes.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>,
                ])}
              </div>

              <div className="space-y-6 border-t px-4 py-6"></div>
            </div>
          </div>
        </div>

        <header className="relative">
          <nav aria-label="Top">
            <GlobalLoading />

            <div className="bg-panel">
              <div className="mx-auto max-w-7xl border-b px-4 sm:px-6 lg:border-none lg:border-transparent lg:px-8">
                <div className="lg:border-b">
                  <div className="flex h-12 items-center justify-between md:h-16">
                    <div className="hidden flex-none lg:flex lg:items-center">
                      <Link to="/" prefetch="render" unstable_viewTransition>
                        <img className="h-8 w-auto" src={logo} alt="" />
                      </Link>
                    </div>

                    <div className="hidden h-full lg:flex">
                      <div className="ml-8">
                        <div className="flex h-full justify-center space-x-8">
                          {main?.attributes.items &&
                            sortByField(
                              main.attributes.items.data,
                              (i) => i.attributes.order,
                            ).map((item) => (
                              <NavbarItem
                                key={item.attributes.url}
                                item={item}
                              />
                            ))}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-1 items-center lg:hidden">
                      <button
                        onClick={() => setShowMobileNav(true)}
                        type="button"
                        className="-ml-2 rounded-md bg-white p-2"
                      >
                        <Icon name="Menu" />
                      </button>

                      <LazySearchWidget
                        apiKey={algoliaApiKey}
                        appId={algoliaAppId}
                        className="ml-2 lg:hidden"
                        triggerClassName="h-12"
                      />
                    </div>

                    <Link
                      unstable_viewTransition
                      to="/"
                      className="lg:hidden"
                      prefetch="render"
                    >
                      <span className="sr-only">Curtains24.de</span>
                      <img
                        src={logo}
                        alt=""
                        className="h-8 w-24 flex-none object-contain"
                      />
                    </Link>

                    <div className="flex flex-1 items-center justify-end">
                      <div className="flex items-center lg:ml-8">
                        <LazySearchWidget
                          apiKey={algoliaApiKey}
                          appId={algoliaAppId}
                          className="ml-2 hidden lg:flex"
                          triggerClassName="h-12"
                        />

                        <span
                          className="mx-4 h-6 w-px bg-gray-200 lg:mx-6"
                          aria-hidden="true"
                        ></span>

                        <div className="flow-root">
                          <Link
                            unstable_viewTransition
                            to="/warenkorb"
                            className="group -m-2 flex items-center p-2"
                          >
                            <Icon name="ShoppingCart" />
                            <span className="ml-2 text-sm font-medium">
                              {cartCount}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>

        {!!editPolygonId && (
          <EditMessage message={topBar?.configurationEditButton ?? ''} />
        )}
      </div>
    </>
  );
}

export type TopBarProps = {
  items: TopBarItemProps[];
  aboutLabel?: string;
  configurationEditButton?: string;
};

const TopBar: React.FC<TopBarProps> = ({ items }) => {
  return (
    <div
      style={{ viewTransitionName: 'topBar' }}
      className="bg-primary text-primary-foreground"
    >
      <div className="mx-auto flex h-8 max-w-7xl items-center justify-between px-4 sm:px-6 md:h-10 lg:px-8">
        <div className="flex flex-1 justify-center space-x-4 text-center lg:flex-none">
          {items.map((item, i) => (
            <Fragment key={i}>
              <TopBarItem {...item} />
              {i < items.length - 1 && (
                <span
                  className={cn('h-6 w-px bg-primary-foreground', {
                    'hidden lg:block': !item.showOnMobile,
                  })}
                  aria-hidden="true"
                ></span>
              )}
            </Fragment>
          ))}
        </div>

        <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
          <Link to="/ueber-curtains24">Über Curtains24.de</Link>
        </div>
      </div>
    </div>
  );
};

export type TopBarItemProps = {
  label: string;
  link?: string;
  showOnMobile: boolean;
};

const TopBarItem: React.FC<TopBarItemProps> = ({
  link,
  showOnMobile,
  label,
}) => {
  type LinkType = 'none' | 'internal' | 'external';

  const linkType: LinkType = link
    ? link.startsWith('http')
      ? 'external'
      : 'internal'
    : 'none';

  const Wrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    switch (linkType) {
      case 'internal':
        return (
          <Link
            to={link ?? ''}
            className={cn({
              'hidden lg:block': !showOnMobile,
            })}
            prefetch="viewport"
            unstable_viewTransition
          >
            {children}
          </Link>
        );
      case 'external':
        return (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={cn({
              'hidden lg:block': !showOnMobile,
            })}
          >
            {children}
          </a>
        );
      case 'none':
        return (
          <span
            className={cn({
              'hidden lg:block': !showOnMobile,
            })}
          >
            {children}
          </span>
        );
    }
  };

  return (
    <>
      <Wrapper>{label}</Wrapper>
    </>
  );
};
