import type { ClientLoaderFunctionArgs} from '@remix-run/react';
import { useLoaderData , Outlet } from '@remix-run/react';
import type { LoaderFunctionArgs } from '@remix-run/node';
import { getCurrentSession } from '~/modules/cart/backend/session.server';
import { CartSession } from '~/modules/cart/backend/cart-session';
import Navbar from '~/modules/layout/components/nav/navbar';
import Footer from '~/modules/layout/components/nav/footer';
import type { RequireCartResponse } from '~/modules/cart/backend/cart.server';
import type { JsonifyObject } from 'type-fest/source/jsonify';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await getCurrentSession(request);
  const editPolygonJson = session.get(CartSession.EditPolygon) as
    | string
    | undefined;

  let editPolygon: RequireCartResponse['editPolygon'] | undefined;
  if (editPolygonJson) {
    editPolygon = JSON.parse(editPolygonJson);
  }

  return {
    editPolygonId: editPolygon?.id,
    algoliaAppId: process.env.ALGOLIA_APP_ID ?? '',
    algoliaApiKey: process.env.ALGOLIA_API_KEY ?? '',
    cartCount: 0,
  };
};

export const clientLoader = async ({
  serverLoader,
}: ClientLoaderFunctionArgs) => {
  const serverData: JsonifyObject<{
    editPolygonId: string | undefined;
    cartCount: number;
    algoliaAppId: string;
    algoliaApiKey: string;
  }> = await serverLoader();

  const res = await fetch('/api/cart/count');
  const data: { count: number } = await res.json();
  serverData.cartCount = data.count;

  return serverData;
};

clientLoader.hydrate = true;

const LayoutPage: React.FC = () => {
  const { editPolygonId, cartCount, algoliaApiKey, algoliaAppId } =
    useLoaderData<typeof loader>();
  return (
    <>
      <Navbar
        editPolygonId={editPolygonId}
        algoliaApiKey={algoliaApiKey}
        algoliaAppId={algoliaAppId}
        cartCount={<span>{cartCount}</span>}
      />
      <Outlet />
      <Footer />
    </>
  );
};

export default LayoutPage;
