import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useNavigation } from '@remix-run/react';
import { cn } from '~/lib/utils';

function GlobalLoading() {
  const navigation = useNavigation();
  const active = navigation.state !== 'idle';
  const ref = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = React.useState(true);

  useEffect(() => {
    if (!ref.current) return;
    if (active) setAnimationComplete(false);

    Promise.allSettled(
      ref.current.getAnimations().map(({ finished }) => finished),
    ).then(() => !active && setAnimationComplete(true));
  }, [active]);

  return (
    <div
      style={{ viewTransitionName: 'global-loading' }}
      role="progressbar"
      aria-hidden={!active}
      aria-valuetext={active ? 'Loading' : undefined}
      className="inset-x-0 left-0 top-0 z-50 h-1"
    >
      <div
        ref={ref}
        className={cn(
          'h-full bg-accent transition-all duration-500 ease-in-out',
          navigation.state === 'idle' &&
            animationComplete &&
            'w-0 transition-none',
          navigation.state === 'submitting' && 'w-4/12',
          navigation.state === 'loading' && 'w-10/12',
          navigation.state === 'idle' && !animationComplete && 'w-full',
        )}
      />
    </div>
  );
}

export default GlobalLoading;
