import logo from '~/assets/images/curtains24-black-yellow.svg';
import { useNav } from '~/modules/layout/components/nav/nav-provider';
import { cn } from '~/lib/utils';
import { Link } from '@remix-run/react';

export type FooterColumn = {
  title: string;
  links: { name: string; href: string }[];
};

export type FooterProps = {
  dynamicContent: string;
  groupedColumns: [FooterColumn, FooterColumn?][];
  socials: {
    name: string;
    href: string;
    icon: string;
  }[];
};

export default function Footer() {
  const { footer } = useNav();

  return (
    <footer
      className="bg-secondary"
      aria-labelledby="footer-heading"
      style={{ viewTransitionName: 'footer' }}
    >
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-4">
            <img className="h-8 w-auto" src={logo} alt="" />
            <div
              dangerouslySetInnerHTML={{ __html: footer?.dynamicContent ?? '' }}
              className="prose-sm text-gray-600"
            ></div>
            <div className="flex space-x-6">
              {footer?.socials.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <div
                    className="h-6 w-6"
                    style={{
                      mask: `url(${item.icon}) no-repeat center`,
                      backgroundColor: 'currentColor',
                      maskSize: 'cover',
                    }}
                  ></div>
                </a>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            {footer?.groupedColumns.map((group, i) => (
              <div
                key={'group' + i}
                className="md:grid md:grid-cols-2 md:gap-8"
              >
                {group.map((column, index) => (
                  <div
                    key={'column' + index}
                    className={cn({
                      'mt-10 md:mt-0': index > 0,
                    })}
                  >
                    <h3 className="text-sm font-semibold leading-6 text-gray-900">
                      {column?.title}
                    </h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {column?.links.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                          >
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-500">
            Copyright &copy; {new Date().getFullYear()} Curtains24.de
          </p>
        </div>
      </div>
    </footer>
  );
}
