import React, { lazy, memo, Suspense, useState } from 'react';
import Icon from '~/components/ui/icon';
import { cn } from '~/lib/utils';

const SearchWidget = lazy(() => import('./search-widget'));

const LazySearchWidget: React.FC<{
  className?: string;
  triggerClassName?: string;
  appId: string;
  apiKey: string;
}> = (props) => {
  const [loaded, setLoaded] = useState(false);

  const PlaceHolder: React.FC = memo(() => (
    <div className={props.className}>
      <div
        className={cn(
          'flex items-center justify-center',
          props.triggerClassName,
        )}
      >
        <Icon
          className="cursor-pointer"
          onClick={() => setLoaded(true)}
          name="Search"
        />
      </div>
    </div>
  ));

  return (
    <>
      {!loaded && <PlaceHolder />}
      {loaded && (
        <Suspense fallback={<PlaceHolder />}>
          <SearchWidget isLazy={true} {...props} />
        </Suspense>
      )}
    </>
  );
};

export default LazySearchWidget;
