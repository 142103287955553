import Icon from '~/components/ui/icon';
import { Form } from '@remix-run/react';

const EditMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="mx-auto max-w-7xl md:px-8">
      <Form action="/cart/cancel-edit" method="post">
        <button
          name="id"
          value="1"
          className="flex w-full items-center justify-center  bg-sky-600 py-1 text-center text-destructive-foreground"
          type="submit"
        >
          <Icon name="Info" className="hidden sm:block" />
          <span className="ml-2">{message}</span>
        </button>
      </Form>
    </div>
  );
};

export default EditMessage;
