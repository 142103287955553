import { Link } from '@remix-run/react';
import { Button } from '~/components/ui/button';
import type { MenuItem } from '~/lib/strapi/types/menu-item';
import { sortByField } from '~/lib/utils';

type NavbarItemProps = {
  item: MenuItem;
};

export default function NavbarItem(props: NavbarItemProps) {
  const { item } = props;

  return [
    <div key={item.attributes.url} className="flex">
      <div className="relative flex items-center">
        <Button asChild variant="link" className="text-card-foreground">
          <Link
            prefetch="intent"
            unstable_viewTransition
            to={item.attributes.url}
          >
            {item.attributes.title}
          </Link>
        </Button>
      </div>
    </div>,
    ...sortByField(
      item.attributes.children?.data ?? [],
      (i) => i.attributes.order,
    ).map((i) => <NavbarItem item={i} key={i.attributes.url} />),
  ];
}
